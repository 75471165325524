export default {
    // banner
    'career.banner.title': `Make fun make sense`,
    'career.banner.description': '欢迎加入我们！',
    'career.banner.buttonText': `浏览职位`,
    // content 1
    'career.content1.title': '关于我们',
    'career.content1.description': `我们相信：简单、坦诚、自驱、上进、分享、追求极致的氛围可以激发团队的活力与创造力`,
    // content 2
    'career.content2.1.title': 'Maria',
    'career.content2.1.subtitle': `Unity3D工程师`,
    'career.content2.1.description': `“在HABBY,能够和很多志同道合的小伙伴一起创造精品的游戏,让很多可能变成了现实！”`,
    'career.content2.2.title': 'Joel',
    'career.content2.2.subtitle': `产品运营`,
    'career.content2.2.description': `“我感受到HABBY与我曾经工作过的其他公司最大的不同之一就是,公司能够提供真正平等、自由、有爱、团结的工作氛围,并鼓励每个人发现自己更好的一面,成长为更好的自己！”`,
    // content 3
    'career.content3.title': '我们在哪',
    'career.content3.description': `Habby是一家全球化的精品游戏发行公司,目前在新加坡、北京、上海均设有分公司,未来还会不断开拓。`,
    'career.content3.location.1.title': '北京,中国',
    'career.content3.location.1.desc': '10 职位',
    'career.content3.location.2.title': '新加坡,新加坡',
    'career.content3.location.2.desc': '6 职位',
    // content 4
    'career.content4.modal.applyButtonText': '申请职位',
    'career.content4.title': '职位列表',
    'career.content4.description': `如果你想加入海彼,请将您的简历+申请职位发给我：简历接收邮箱：`,
    'career.content4.description.email': 'zhangxinzhe@habby.com',
    'career.content4.jobs.buttonText': '详情',
    'career.content4.jobs': [
        {
            title: '试玩广告开发工程师',
            location: '北京',
            type: '',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1、利用Cocos、Unity3d引擎开发和优试玩广告。\n2、能够使用相关工具将Unity开发的项目转换为试玩广告，保证转换后的产品质量和性能。\n3、解决Unity到H5转换过程中出现的性能问题、兼容性问题及其他技术难题。\n4、与团队合作，不断优化开发流程，提升项目质量和效率。\n5、进行代码审核和性能评估，确保软件质量符合公司标准。\n6、持续研究和掌握行业最新技术，应用于项目中以保持技术领先。'
                },
                {
                    title: '岗位要求',
                    content:
                        '1、计算机科学或相关领域的本科及以上学历。\n2、精通至少一种游戏开发引擎：Cocos、unity。\n3、具备使用Unity引擎开发项目的经验，并熟悉将Unity项目转换为H5的技术和工具。\n4、熟练掌握C#/JavaScript/TypeScript等语言，具有良好的前端开发能力。\n5、具有优化H5应用性能的经验，了解前端性能优化的最佳实践。\n6、良好的问题解决能力，能够独立面对和解决技术难题。\n6、强烈的责任心和团队合作精神。'
                },
                {
                    title: '补充要求',
                    content:
                        '1、熟悉引擎渲染原理，具有在实际工作中优化渲染性能的经验。\n2、具备包体压缩优化经验，并在项目中成功应用。'
                },
                {
                    title: '加分项',
                    content: '1、具有大型项目的H5试玩广告开发经验。'
                }
            ]
        },
        {
            title: '游戏客户端开发工程师',
            location: '北京',
            type: '视频设计',
            descriptions: [
                {
                    title: '职位要求',
                    content:
                        '1. 4年以上unity前端逻辑开发经验。\n2.熟练掌握UGUI的使用，对UGUI有深入的理解。\n3.熟练使用Unity进行扩展工具的开发。\n4.熟练掌握lua脚本。'
                },
                {
                    title: '综合素质',
                    content:
                        '1.（重要）有丰富的业务逻辑设计和开发经验，善于优化和重构业务相关逻辑结构。\n2. 逻辑思维能力强，学习能力强，品质导向。\n3. 具有良好的沟通能力，善于表达自己的观点。\n4. 能够快速进行框架搭建和原型设计。'
                }
            ]
        },
        {
            title: '资深游戏测试',
            location: '北京',
            type: '测试',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1、负责移动游戏产品测试，快速响应测试需求，保证测试业务有序开展；\n2、在测试过程中，迅速、准确、全面的找出游戏中的问题，并对问题产生原因做基本判断；\n3、熟悉项目开发及测试流程与各种测试技巧，熟悉测试用例及测试文档，测试报告的编写；\n4、数据驱动，优化测试全流程，梳理测试标准，提升测试质量和效率；\n5、研究测试技术、推动测试工具的设计研发，提升测试团队战斗力；'
                },
                {
                    title: '岗位要求',
                    content:
                        '1、计算机相关专业，本科及以上学历，5年及以上测试行业经验；\n2、有较强的逻辑分析能力和学习能力，自驱能力强，对项目质量负责，推动项目持续改进；\n3、良好的技术能力，至少熟练掌握一种编程语言；有技术热情，积极了解测试领域的技术进展；\n4、对游戏有足够热情，能够接受并投入各类游戏的测试工作；\n5、对个人看法能够精准描述；做事认真仔细、有耐心，踏实稳重，有良好的语言表达能力的协作沟通能力及逻辑思维能力，工作责任心强。'
                }
            ]
        },
        {
            title: '资深U3D客户端开发工程师',
            location: '北京',
            type: '研发',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1. 使用Unity3D工具引擎，负责Android、iOS平台游戏的设计、开发；\n2.负责游戏客户端架构的搭建，核心工程模块开发；\n3.与策划、美术、服务器开发人员保持良好沟通，协助建立高效的游戏内容制作流程以及标准，持续完善并优化工具链；\n4. 对客户端开发质量负责，使游戏在各档次机器上都有流畅表现；\n5. 研究游戏客户端前沿技术，制定游戏技术方向并寻找可行的落地方案；'
                },
                {
                    title: '岗位要求',
                    content:
                        '1.全日制本科以上学历，计算机相关专业；熟练使用Unity3D，熟悉引擎的底层运行原理；\n2. 5年以上3D游戏开发经验，负责过至少一款已上线的3D游戏的完整研发和迭代项目，熟悉3D游戏开发全流程；\n3. 熟悉手机游戏开发的适配和优化，了解U3D和手机系统原生语言的交互；\n4. 熟练使用C#、Lua等语言进行游戏核心功能开发，有渲染和性能优化等知识和经验者优先；\n5. 编码能力强, 热爱游戏事业，善于沟通，具备良好的责任心和抗压能力。'
                }
            ]
        },
        {
            title: '游戏关卡策划',
            location: '北京',
            type: '游戏关卡策划',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1、负责关卡内容制作，包括玩法设计与规划，关卡难度以及节奏的把控等；\n2、对美术表现有较为丰富的想法，能提出有趣合理的设计方案；\n3、围绕游戏框架和关卡内容，设计相关的玩法系统；\n4、跟进欧美市场热门休闲玩法，理解其设计方法和思路，例如match-3、tripeaks、word、hidden objects等；'
                },
                {
                    title: '岗位要求',
                    content:
                        '1、喜欢休闲游戏；\n2、能够敏锐的感知到游戏中的一些正负向体验，并从中推断出对应的设计原因；\n3、五年以上休闲类游戏关卡设计经验，若同时有系统设计经验优先。'
                }
            ]
        },
        {
            title: '游戏运营宣传设计师',
            location: '北京',
            type: '运营',
            descriptions: [
                {
                    title: '岗位要求',
                    content:
                        '1.总体要求：热情+自驱 + 高审美 + 有趣\n2.审美好，创意能力强，做出能打动玩家的设计\n3.具备出色的平面设计能力和一定的插画水准（辅助创意），具备用不多的游戏资源设计出高品质运营宣传图的能力\n4.擅长欧美卡通方向\n5.强目标感，有明确追求的方向，对自己所做有高品质要求\n6.自发推进，可以在一个开放自由的工作环境工作推进出好的结果\n7.开放意识和心态，接受新的方法，工具和优化现有的工作模式\n8.思维表达清晰，沟通简单高效且逻辑清楚\n9.了解并熟悉常用AI绘图工具，如SD、MJ等\n10.对游戏有着浓厚的热情，对Habby游戏有热情为佳'
                },
                {
                    title: '岗位职责',
                    content:
                        '1.和运营团队紧密配合，讨论和推进创意，负责上线游戏的运营活动的物料设计\n2. 使用现有项目的美术资源，结合自身美术能力，表达创意，创作出不同场景需求使用的插图或者平面设计\n3. 根据结果总结积累经验，优化设计和效率\n4. 与外包团队沟通协作，能独立把控外包设计质量和进度\n5.快速学习了解新的工具和方法，提升效率和质量，应用到实际项目'
                }
            ]
        },
        {
            title: '游戏高级UI设计师',
            location: '北京',
            type: '美术',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1.负责游戏内UI界面的整体视觉设计包装；\n2.制定项目的UI风格；'
                },
                {
                    title: '任职要求',
                    content:
                        '1.有较好的平面设计基础，对市场流行的设计审美有成熟判断；\n2.优秀的团队协作能力，能顺滑的衔接与策划，交互，动效，还原，程序合作流程；\n3.优秀的绘制基础，优秀的图形设计，色彩构成，精修刻画能力；对游戏交互有较深理解；\n4.有较强的设计归纳总结能力，在工作中能有一定方法论沉淀，推动团队设计力进步；\n5.优秀的设计把控能力，对基础设计规范和资源输出管理有丰富经验；'
                }
            ]
        },
        {
            title: '资深3D美术设计师',
            location: '北京',
            type: '主美',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1、使用u3d引擎根据需求独立完成游戏整体氛围的设计制作和效果的调整 ;\n2、负责游戏模型及贴图的制作，能够达到符合市场预期的品质；\n3、与策划，设计，技术等相关部门保持紧密的合作与沟通；\n4、了解动画，特效等其它环节者优先考虑 ;'
                },
                {
                    title: '岗位要求',
                    content:
                        '1、有5年以上的3D游戏制作工作经验；\n2、熟悉PBR制作流程及相关制作软件(PS，3DMAX/MAYA，Zb，SP等) ;\n3、熟练掌握Unity的材质，灯光，后处理等模块 ;\n4、能独立使用地图编辑器，完成模型和地编的制作 ;\n5、对环境和空间的布局有较强能力（对地貌，建筑，植被有很好的表现能力）；\n6、具备良好的沟通和团队合作能力；\n7、具备良好的自驱力,有自学能力及强烈的学习意愿；\n8、对于游戏开发充满激情，做事积极主动 ；'
                }
            ]
        },
        {
            title: '游戏原画师(角色+场景)',
            location: '北京',
            type: '游戏原画',
            descriptions: [
                {
                    title: '关键词',
                    content:
                        '欧美卡通、角色+场景、审美好、沟通好、思维跳跃、潮、对国外流行比较了解'
                },
                {
                    title: '总体要求',
                    content:
                        '1. 擅长欧美卡通方向，匹配欧美卡通项目风格\n2.强目标感，有明确追求的方向，对自己所做有品质的要求\n3.独立自主与项目成员沟通拆解需求，确定解决方案并且按时落地结果\n4.自发推进，在一个开放自由的工作环境工作并且对结果负责\n5.有任务管理和时间计划，可以并行安排好多不同优先级的任务需求\n6.开放意识和心态，接受新的方法和优化现有的工作模式\n7.思维表达清晰，沟通逻辑清楚\n8.有大公司和成功项目的经验优先，对游戏有着浓厚的热情，有自己对于游戏的理解，对Habby游戏有热情为佳'
                },
                {
                    title: '岗位要求',
                    content:
                        '1.3年以上游戏开发经验，和项目团队紧密配合，独立自主沟通拆解需求确定方案，在时间计划内完成角色和场景的原画设计，推进项目的设计进度\n2.和其他美术职能紧密配合，调试以寻求最佳呈现结果\n3.沟通简单顺畅，可以高效清晰讨论问题，开放思维，能接受不同意见\n4.有关于美术设计的方法论和经验，可以创造有趣且吸引人的角色，场景等游戏内的元素'
                }
            ]
        },
        {
            title: '游戏TA',
            location: '北京',
            type: '研发',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1. 项目初期按照制作人要求搭建符合Demo风格的3D场景和角色效果(没有原画设计,只有需求描述);\n- 场景模型制作以及unity场景搭建, 材质调整和氛围调试 ;\n- 角色模型和贴图制作以及unity材质效果 ;\n- 配合策划和程序同学推进Demo的进度 ; \n2. 寻找符合的3D素材或者直接制作3D资源 ;\n3. 动作接入引擎(有动作同学帮助) ;\n4. 特效接入引擎(有特效同学帮助);'
                },
                {
                    title: '岗位要求',
                    content:
                        '1.配合项目Demo制作符合项目要求的美术资源\n2.负责Demo中的模型和材质制作'
                },
                {
                    title: '任职要求',
                    content:
                        '1.能够熟练使用unity的模型材质灯光等模块\n2.熟练掌握unity的shader编写(Build-in和URP) \n3.了解unity的动画和特效模块\n4.（有PBR制作能力或三渲二项目经验者优先）'
                },
                {
                    title: '总体要求',
                    content:
                        '1.擅长偏欧美卡通方向，审美高\n2.强目标感，有明确追求的方向，对自己产出有品质的要求\n3.自发推进，可以在一个开放自由的工作环境工作并且对结果负责\n4.开放意识和心态，接受新的方法和优化现有的工作模式\n5.思维表达清晰，沟通简单且逻辑清楚\n6.对游戏有着浓厚的热情，有自己对于游戏的理解，对Habby游戏有热情为佳'
                }
            ]
        },
        {
            title: '游戏动画师',
            location: '北京',
            type: '研发',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1.制作游戏UI动效，界面切换演示、过场、交互反馈等动画；\n2.参与需求的前期设计，配合UI提出交互方案；'
                },
                {
                    title: '任职要求',
                    content:
                        '1.动画专业本科以上学历，了解视觉引导等交互相关设计语言；\n2.有坚实的动画节奏表现能力，动画设计有新意；\n3.熟练使用Particle system 、 Unity2DAnimation ；\n4.可以使用AE、XD等软件制作交互演示流程；\n5.热爱游戏，审美好，对工作热情主动，认自负责，具有良好的团队合作意识和沟通技巧。'
                }
            ]
        },
        // BASE 新加坡
        {
            title: 'Senior 2D Animator',
            location: 'Singapore',
            type: 'Operator',
            descriptions: [
                {
                    title: '岗位职责',
                    content:
                        '1.为游戏资产、过场动画等制作视觉效果出色的UI、2D动画和特效；\n2.与游戏开发团队合作，确保所有资产符合技术要求和性能标准；\n3.构思并开发最适合休闲移动游戏的原创动画创意；\n4.保持所有游戏元素的一致性和高质量的美学标准。\n5.通过利用最佳实践和工具，识别和优化动画制作流程。\n6.关注行业趋势、技术和动画领域的新兴技术。'
                },
                {
                    title: '任职要求',
                    content:
                        '1.5年以上西方休闲或中核游戏移动游戏开发经验；熟悉西方风格的游戏美术。\n2.拥有完整的Unity移动游戏开发经验；\n3.能够独立制作UI、2D动画和特效等；\n4.接受团队反馈时谦虚且尊重；\n5.对游戏充满热情！'
                }
            ]
        },
        //2024.12.4 新加坡新增
        {
            title: 'Marketing Video Content Designer (KR)',
            location: 'Seoul',
            type: 'Marketing Video Content Designer (KR)',
            full: 'Full Time(Hybrid)',
            descriptions: [
                {
                    title: '직무 설명:',
                    content:
                        '- 한국 시장을 대상으로 창의적인 UA 및 브랜딩 영상, 마케팅 캠페인, 소셜 미디어, 프로모션용 광고 영상 콘텐츠 제작 \n- 모바일 게임 산업의 최신 트렌드를 반영하여 영상 제작\n- 영상 성과와 마케팅 팀의 피드백을 바탕으로 전략 분석 및 제작'
                },
                {
                    title: '자격 요건:',
                    content:
                        '- 게임 또는 엔터테인먼트 업계에서 2년 이상의 영상 콘텐츠 제작 및 편집 경력이 있으신 분\n- Adobe Premiere Pro (PR), AR, After Effects 등의 영상 편집 소프트웨어에 능숙하신 분\n- 빠르게 변화하는 환경에서 협업하며 기한을 맞출 수 있는 뛰어난 소통 능력이 있으신 분\n- 게임 산업 트렌드에 대한 깊은 이해가 있으신 분\n- 시각적 미적 감각과 스토리텔링 능력이 뛰어나며 세부 사항에 대한 높은 주의력이 있으신 분'
                },
                {
                    title: '우대 사항:',
                    content:
                        '- 영어 또는 중국어 가능자\n- 마케팅에 대한 깊은 이해가 있으신 분\n- 3D 프로그램 사용 경험이 있으신 분'
                }
            ]
        },
        {
            title: 'Marketing Video Content Designer (JP)',
            location: 'Tokyo',
            type: 'Marketing Video Content Designer (JP)',
            full: 'Full Time(Hybrid)',
            descriptions: [
                {
                    title: '職務内容：',
                    content:
                        '- 日本市場をターゲットにした、革新的なユーザー獲得（UA）、ブランド動画広告、およびマーケティングキャンペーン、SNS、プロモーション用の動画コンテンツを制作する。\n- モバイルゲーム業界の最新トレンドを把握し、これらの洞察を動画戦略に取り入れる。\n- 動画のパフォーマンスやマーケティングチームからのフィードバックに基づいて戦略を調整する。'
                },
                {
                    title: '応募条件：',
                    content:
                        '- 動画コンテンツ制作および編集における2年以上の実務経験（ゲームまたはエンターテインメント業界での経験が望ましい）。\n- Adobe Premiere Pro (PR)、After Effects、ARなどの動画編集ソフトウェアに精通していること。\n- 優れたコミュニケーション能力を持ち、迅速なペースでのコラボレーションや締め切り遵守が可能であること。\n- ゲーム業界のトレンドに対する深い理解を持つこと。\n- 優れたストーリーテリング能力、強い視覚的センス、および細部への注意力を有すること。'
                },
                {
                    title: '優遇条件：',
                    content:
                        '- 英語または中国語能力\n- カジュアルゲームマーケティングに対する深い理解\n- 3Dプロジェクトの経験'
                }
            ]
        },
    ]
};
